<section class="page-user-profile">
  <div class="row justify-content-center align-items-center">
    <div class="col-12">
      <app-banner></app-banner>
    </div>
  </div>

  <!-- Profile posts and info starts -->
  <div class="row profile-info-posts">
    <!-- ======================================================================== -->
    <!-- 1st column starts -->
    <div class="col-lg-3 col-12">
      <div class="row">
        <!-- About starts -->
        <div class="col-12">
          <div class="card">
            <div class="card-header d-flex justify-content-between align-items-center">
              <h4 class="card-title m-0">About</h4>
              <span class="cursor-pointer"><i class="ft-more-vertical-"></i></span>
            </div>
            <div class="card-content">
              <div class="card-body">
                <p class="m-0">
                  I am interested in Web Programming, Building websites. Worked as a freelancer
                  Front End Developer. I am keen to gain more experience in the field. For this
                  reason, i am looking for a company willing to offer me a placement among their web
                  designers. I hope to be Full Stack Web Developer, and i am learning the track now.
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- About ends -->

        <!-- Education starts -->
        <div class="col-12">
          <div class="card">
            <div class="card-header d-flex justify-content-left align-items-center">
              <i class="fa fa-graduation-cap font-large-1 mr-2"> </i>
              <h4 class="card-title m-0">Education</h4>
            </div>
            <div class="card-content">
              <div class="card-body">
                <ul class="list-unstyled mb-0">
                  <li class="d-flex align-items-center mb-2">
                    <i class="ft-briefcase mr-2 cursor-pointer"></i>
                    <span>
											Bachelor’s degree in Computer Science,
											<a href="http://www.akhbaracademy.edu.eg/" target="_blank"
                      >Akhbar El-Yom Academy
											</a>
										</span>
                  </li>

                  <li class="d-flex align-items-center mb-2">
                    <i class="ft-briefcase mr-2 cursor-pointer"></i>
                    <span> Cumulative grade: Very Good with honors. </span>
                  </li>

                  <li class="d-flex align-items-center mb-2">
                    <i class="ft-file-text mr-2 cursor-pointer"></i>
                    <span>
											Graduation Project: Tracking system (web app, mobile app and GPS device) for
											helping families finding and keep their children safe from kidnapping.
										</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Education ends -->

        <!-- Work Experience starts -->
        <div class="col-12">
          <div class="card">
            <div class="card-header d-flex justify-left-between align-items-center">
              <i class="fa fa-code font-large-1 mr-2"> </i>
              <h4 class="card-title m-0">Work Experience</h4>
            </div>
            <div class="card-content">
              <div class="card-body">
                <ul class="list-unstyled mb-0">
                  <li class="d-flex align-items-center">
                    <i class="ft-briefcase mr-2 cursor-pointer"></i>
                    <span>
											Angular Developer at
											<a href="https://mashura.co/" target="_blank">Mashura Consultants</a>
										</span>
                  </li>
                  <li class="d-flex align-items-center">
                    <i class="ft-briefcase mr-2 cursor-pointer"></i>
                    <span>
											Flutter Developer at
											<a href="https://mashura.co/">Mashura Consultants </a>
										</span>
                  </li>
                  <li class="d-flex align-items-center">
                    <i class="ft-file-text mr-2 cursor-pointer"></i>
                    <span>
											Front-End Developer at
											<a href="javascript:;">Soft Square</a>
										</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Work Experience ends -->


      </div>
    </div>
    <!-- 1st column ends -->

    <!-- ===================================================================== -->
    <!-- 2nd column starts -->
    <div class="col-lg-9 col-12">
      <app-projects></app-projects>
    </div>
    <!-- 2nd column ends -->

  </div>
  <!-- Profile posts and info ends -->
</section>
